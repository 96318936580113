const initProductCarousel = (element) => {
    const $element = $(element);

    const settings = {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        infinite: false,
        mobileFirst: true,
        swipeToSlide: true,
        nextArrow: $element.find('.ob-productCarousel__next'),
        prevArrow: $element.find('.ob-productCarousel__prev'),
        responsive: [
            {
                breakpoint: 567,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3.2,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 801,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    arrows: true,
                },
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    arrows: true,
                },
            },
        ],
    };

    const $carousel = $element.find('.ob-productCarousel__carousel');

    $carousel.slick(settings);
};

export default function () {
    $('.ob-productCarousel').each((index, element) => initProductCarousel(element));
}
