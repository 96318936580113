const getCategoriesFromProducts = (products) => {
    const categories = products.reduce((acc, product) => {
        if (product.category) {
            product.category.forEach(category => {
                if (!acc.includes(category)) {
                    acc.push(category);
                }
            });
        }

        return acc;
    }, []);

    return categories;
};

const createCategoryFilters = (products, categoryTree) => {
    const uniqueCategories = getCategoriesFromProducts(products);

    const categoryFilters = categoryTree.reduce((acc, category) => {
        const match = uniqueCategories.find(c => c === category.name);

        if (match) {
            acc.push(match);
        }

        return acc;
    }, []);

    return categoryFilters;
};

const createFilterButton = (category) => $(`
        <button type="button" class="ob-btnTag ob-productCarousel__filterBtn" data-filter="${category}">
            ${category}
        </button>
    `);

const createAllFilterButton = () => $(`
        <button type="button" class="ob-btnTag ob-productCarousel__filterBtn active" data-filter="All">
            All
        </button>
    `);

const createFilterButtons = (categories) => [createAllFilterButton(), ...categories.map(createFilterButton)];

const mountFilterButtons = ($context, filterButtons) => {
    const $filterButtonsDesktop = $context.find('.ob-productCarousel__filters');
    $filterButtonsDesktop.append(filterButtons);
};

const bindFilterButtons = ($context) => {
    const $carousel = $context.find('.ob-productCarousel__carousel');
    const $filterButtons = $context.find('.ob-productCarousel__filterBtn');

    $filterButtons.on('click', (event) => {
        const $target = $(event.target);

        $filterButtons.removeClass('active');
        $target.addClass('active');

        $carousel.slick('slickUnfilter');

        $carousel.slick('slickFilter', (index, element) => {
            const $element = $(element);
            const categories = $element.data('categories');
            const filter = $target.data('filter');

            if (filter === 'All') {
                return true;
            }

            return categories.includes(filter);
        });
    });
};

function initCarouselFilters(element, pageProducts, categoryTree) {
    const $element = $(element);
    const productList = $element.data('product-list');

    const carouselProducts = pageProducts[productList];

    if (!carouselProducts) {
        return console.error(`ob-product-carousel-filters.js: pageProducts.${productList} is not defined. Inject the products object from handlebars into the js context`);
    }

    const categoryFilters = createCategoryFilters(carouselProducts, categoryTree);
    const filterButtons = createFilterButtons(categoryFilters);

    mountFilterButtons($element, filterButtons);
    bindFilterButtons($element);
}

/**
 *
 *  Main function to initialize the product carousel filters
 *
 * @param {*} context
 */
export default function (context) {
    const $carousels = $('.ob-productCarousel[data-show-filters]');

    if ($carousels.length === 0) {
        return;
    }

    if (!context) {
        return console.error('ob-product-carousel-filters.js: context is not defined');
    }

    const {
        pageProducts,
        categoryTree,
    } = context;

    if (!categoryTree) {
        return console.error('ob-product-carousel-filters.js: categoryTree is not defined. Inject the categories object from handlebars into the js context');
    }

    if (!pageProducts) {
        return console.error('ob-product-carousel-filters.js: pageProducts is not defined. Inject the products object from handlebars into the js context');
    }

    $('.ob-productCarousel[data-show-filters]').each((index, element) => initCarouselFilters(element, pageProducts, categoryTree));
}
