/* eslint-disable no-use-before-define */
import utils from '@bigcommerce/stencil-utils';
import 'regenerator-runtime';
/*
 *
 *  CART PREVIEW
 *
 */
const requestCartPreview = () => new Promise((resolve, reject) => {
    utils.api.cart.getContent({ template: 'ob-custom/header/bttn-cart-preview' }, (err, response) => {
        if (err) {
            reject(err);
        } else {
            const $response = $(response);
            const cartQuantity = $response.data('cart-quantity');

            $('body').trigger('cart-quantity-update', cartQuantity);

            resolve(response);
        }
    });
});

const requestRemoveItem = (productId) => new Promise((resolve, reject) => {
    utils.api.cart.itemRemove(productId, (err, response) => {
        if (err) {
            reject(err);
        } else {
            resolve(response);
        }
    });
});

const onRemoveItem = async (productId, $cartPreviewContainer) => {
    handleCartLoading($cartPreviewContainer);

    await requestRemoveItem(productId);

    const cartPreview = await requestCartPreview();

    handleCartLoaded($cartPreviewContainer, cartPreview);
};


const bindCartPreview = ($cartPreview) => {
    $cartPreview.find('.close-preview').on('click', (e) => {
        e.preventDefault();
        $cartPreview.removeClass('is-open');
    });

    $cartPreview.find('.previewCartItem .previewCartItem__remove').on('click', async (e) => {
        e.preventDefault();
        const productId = $(e.currentTarget).closest('.previewCartItem').data('product-id');
        onRemoveItem(productId, $cartPreview);
    });
};

const handleCartLoading = ($cartPreviewContainer) => {
    const $cartLoading = $('<div class="loadingOverlay"></div>');

    $cartPreviewContainer.html($cartLoading);
    $cartPreviewContainer.addClass('is-loading');
    $cartLoading.show();
};

const handleCartLoaded = ($cartPreviewContainer, cartPreview) => {
    $cartPreviewContainer.removeClass('is-loading');
    $cartPreviewContainer.html(cartPreview);
    bindCartPreview($cartPreviewContainer);
};


const handleCartPreview = async ($cartPreviewContainer) => {
    handleCartLoading($cartPreviewContainer);
    const cartPreview = await requestCartPreview();
    handleCartLoaded($cartPreviewContainer, cartPreview);
};

export default function () {
    const $cart = $('.cartPreview-toggle');

    $cart.on('click', (e) => {
        $('.bttn-navAccount__dropdown').slideUp(200);
        // if the page is cart.php, then don't do anything
        if (window.location.pathname === '/cart.php') {
            return e.stopPropagation();
        }

        if (/Mobi/i.test(navigator.userAgent)) {
            return e.stopPropagation();
        }

        e.preventDefault();
        const $target = $(e.currentTarget);
        const $cartPreview = $target.parent().find('.bttn-cartPreview__dropdown');
        handleCartPreview($cartPreview);
        $cartPreview.slideToggle(200);
    });

    $(document).on('click', (e) => {
        const $target = $(e.target);
        if (
            !$target.closest('.bttn-cartPreview__dropdown').length
            && !$target.closest('.cartPreview-toggle').length
            && !$target.closest('.previewCartItem').length
        ) {
            $('.bttn-cartPreview__dropdown').slideUp(200);
        }
    });

    $('body').on('cart-quantity-update', (event, quantity) => {
        $cart.attr('aria-label', (_, prevValue) => prevValue.replace(/\d+/, quantity));

        $('.cart-quantity')
            .text(quantity);

        $('.countPill')
            .toggle(quantity > 0);

        if (utils.tools.storage.localStorageAvailable()) {
            localStorage.setItem('cart-quantity', quantity);
        }
    });
}

