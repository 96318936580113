import 'focus-within-polyfill';

import './global/jquery-migrate';
import './common/select-option-plugin';
import PageManager from './page-manager';
import quickSearch from './global/quick-search';
import currencySelector from './global/currency-selector';
// import mobileMenuToggle from './global/mobile-menu-toggle';
// import menu from './global/menu';
import foundation from './global/foundation';
import quickView from './global/quick-view';
import cartPreview from './global/cart-preview';
import privacyCookieNotification from './global/cookieNotification';
import carousel from './common/carousel';
import svgInjector from './global/svg-injector';
import collapsibleFactory from './common/collapsible';


import obProductsPerPage from './ob-custom/ob-products-per-page';
import obCardGridCarousel from './ob-custom/bttn-custom/widgets/ob-card-grid-carousel';
import obBrandBannerCarousel from './ob-custom/bttn-custom/widgets/ob-brand-banner-carousel';
import obProductCarouselFilters from './ob-custom/bttn-custom/product-carousel/ob-product-carousel-filters';
import obProductCarousel from './ob-custom/bttn-custom/product-carousel/ob-product-carousel';
import obHeader from './ob-custom/bttn-custom/ob-header';
import obCartPreview from './ob-custom/bttn-custom/ob-cart-preview';
import obMobileMenu from './ob-custom/bttn-custom/ob-mobile-menu';
import obHeroHeading from './ob-custom/bttn-custom/widgets/ob-hero-heading';
import obBundleB2b from './ob-custom/bttn-custom/ob-bundle-b2b';

export default class Global extends PageManager {
    onReady() {
        const { cartId, secureBaseUrl } = this.context;
        
        cartPreview(secureBaseUrl, cartId);
        quickSearch();
        currencySelector(cartId);
        foundation($(document));
        quickView(this.context);
        carousel(this.context);
        privacyCookieNotification();
        svgInjector();

        // Init collapsible
        collapsibleFactory();

        obProductsPerPage(this.context);

        // bttn custom
        obProductCarousel();
        obProductCarouselFilters(this.context);
        obHeader();
        obCartPreview();
        obMobileMenu();

        // widgets
        obCardGridCarousel();
        obBrandBannerCarousel();
        obHeroHeading();

        // Bundle B2B
        obBundleB2b(this.context);
    }
}
