export default function () {
    const $body = $('body');
    const B3StorefrontURL = 'https://cdn.bundleb2b.net/b3-auto-loader.js';

    $body.append(`<script src="${B3StorefrontURL}"></script>`);
    window.b3themeConfig = window.b3themeConfig || {};

    window.b3themeConfig.useText = {
        'invoices.title': 'Invoices',
    };

    const showContent = () => {
        $('.b2b-wrap--placeholder').css('height', 'auto');
        $('.navBar + .b2b-wrap, .b2b-wrap--placeholder').css('opacity', '1');
    };

    window.b3themeConfig.useJavaScript = {
        orders: {
            mounted() {
                showContent();
            },
        },
        usermanagement: {
            mounted() {
                showContent();
            },
        },
        addressbook: {
            mounted() {
                showContent();
            },
        },
        buyagain: {
            mounted() {
                showContent();
            },
        },
        quotelist: {
            mounted() {
                showContent();
            },
        },
        quotes: {
            mounted() {
                showContent();
            },
        },
        shoppinglists: {
            mounted() {
                showContent();
            },
        },
        shoppinglist: {
            mounted() {
                showContent();
            },
        },
        invoices: {
            mounted() {
                showContent();
            },
        },
        invoicespayment: {
            mounted() {
                showContent();
            },
        },
        invoicereceipt: {
            mounted() {
                showContent();
            },
        },
        invoicedetail: {
            mounted() {
                showContent();
            },
        },
        dashboard: {
            mounted() {
                showContent();
            },
        },
    };

    window.b3themeConfig.useContainers = {
        'pdp.shoppinglist.container': '.productView-details .productView-addToShoppingList',
        'dashboard.container': '.ob-pageContainer .page',
        'dashboard.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
        'dashboard.button.container--s': '#navPages-account .navPage-subMenu-list',
        'dashboard.endMasquerade.container': '.navUser',
        'tpa.container': '.page .page-content',
        'tpa.guest.container': '.account-body',
        'tpa.button.container': '.navUser-section--alt',
        'tpa.button.container--s': '#menu .navPages-list--user',
        'shoppinglists.container': '.ob-pageContainer .b2b-wrap--placeholder',
        'shoppinglists.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
        'shoppinglists.button.container--s': '#navPages-account .navPage-subMenu-list',
        'shoppinglist.container': '.ob-pageContainer .page',
        'addressBook.container': '.ob-pageContainer .b2b-wrap--placeholder',
        'addressBook.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
        'addressBook.button.container--s': '#navPages-account .navPage-subMenu-list',
        'orders.container': '.account',
        'orderDetail.container': '.ob-pageContainer .page',
        'quickOrderPad.container': '.ob-pageContainer .page',
        'quickOrderPad.button.container': '.navUser-section--alt',
        'quickOrderPad.button.container--s': '#menu .navPages-list--user',
        'buyAgain.container': '.ob-pageContainer .b2b-wrap--placeholder',
        'buyAgain.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
        'buyAgain.button.container--s': '#navPages-account .navPage-subMenu-list',
        'userManagement.container': '.ob-pageContainer .b2b-wrap--placeholder',
        'userManagement.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
        'userManagement.button.container--s': '#navPages-account .navPage-subMenu-list',
        'rfq.quote.container': '.ob-pageContainer .b2b-wrap--placeholder',
        'quotesList.container': '.ob-pageContainer .b2b-wrap--placeholder',
        'quotesList.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
        'quotesList.button.container--s': '#navPages-account .navPage-subMenu-list',
        'invoices.container': '.ob-pageContainer .b2b-wrap--placeholder',
        'invoicesPayment.container': '.ob-pageContainer .b2b-wrap--placeholder',
        'invoiceDetail.container': '.ob-pageContainer .b2b-wrap--placeholder',
        'invoiceDetail.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
        'invoiceDetail.button.container--s': '#navPages-account .navPage-subMenu-list',
        'invoiceReceipt.container': '.ob-pageContainer .b2b-wrap--placeholder',
        'invoiceReceipt.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
        'invoiceReceipt.button.container--s': '#navPages-account .navPage-subMenu-list',
        'myQuote.button.container': '.navUser-section--alt',
        'myQuote.button.container--s': '#menu .navPages-list--user',
        'cartActions.container': '.cart-actions',
        'addToCart.container': '#add-to-cart-wrapper',
        'addToCart.quickView.container': '.productView--quickView #add-to-cart-wrapper',
    };
}

