function bindCategoryNav() {
  let timer = null, leaveTimer = null;

  const onMouseEnter = (e) => {
    if ($('.quickSearchResults').is(':visible')) {
      return;
    }

    const $target = $(e.currentTarget);

    $('.bttn-navCategories__item').removeClass('is-open');
    $target.addClass('is-open');

    clearTimeout(leaveTimer);

    // Check if the sub-menu is already open before sliding it down
    if (!$target.find('.bttn-navCategories__subNav').is(':visible')) {
      timer = setTimeout(() => {
        $('.bttn-navCategories__subNav').slideUp();
        $target.find('.bttn-navCategories__subNav').slideDown(200);
      }, 350);
    }
  };

  const onMouseLeave = (e) => {
    const $target = $(e.currentTarget);

    clearTimeout(timer);

    leaveTimer = setTimeout(() => {
      $target.removeClass('is-open');
      $target.find('.bttn-navCategories__subNav').slideUp();
    }, 350);
  };

  $('.bttn-navCategories__item').on({
    mouseenter: onMouseEnter,
    mouseleave: onMouseLeave,
  });
}

function bindAccountNav() {
    const $context = $('.navUser');
    const $accountNav = $context.find('.navUser-action.account-toggle');

    $accountNav.on('click', (e) => {
        e.preventDefault();
        const $target = $(e.currentTarget);
        const $accountNavDropdown = $target.parent().find('.bttn-navAccount__dropdown');

        $accountNavDropdown.slideToggle(200);

        $('.bttn-cartPreview__dropdown').slideUp(200);
    });

    $('.bttn-navAccount__dropdownClose').on('click', (e) => {
        e.preventDefault();
        $('.bttn-navAccount__dropdown').slideUp(200);
    });

    $(document).on('click touchstart', (e) => {
        const $target = $(e.target);

        if (!$target.closest($context).length) {
            $('.bttn-navAccount__dropdown').slideUp(200);
        }
    });
}

function bindUserNav() {
    bindAccountNav();
}


function bindNavMenusCategories(){
    const menuContainer = $('.bttn-navCategories');
    const menuItems = $('.bttn-navCategories > div');
    const arrowLeft = $('.left-scroll');
    const arrowRight = $('.right-scroll');

    let totalMenuItemsWidth = 0;
        menuItems.each(function() {
        totalMenuItemsWidth += $(this).outerWidth(true);
    });

    let menuWidth = $(menuContainer).innerWidth();
    let scrollLimit = 0;
    if (menuWidth > 100){
        scrollLimit = menuWidth * 0.30;
    } else {
        scrollLimit = 200;
    }

    if(totalMenuItemsWidth > menuWidth){
        arrowLeft.show();
        arrowRight.show();
    }

    arrowRight.click(function() {
        menuContainer.animate({
          scrollLeft: menuContainer.scrollLeft() + scrollLimit
        }, 'smooth');
    });

    arrowLeft.click(function() {
        menuContainer.animate({
          scrollLeft: menuContainer.scrollLeft() - scrollLimit
        }, 'smooth');
    });
}

export default function () {
    bindCategoryNav();
    bindUserNav();
    bindNavMenusCategories();
}
