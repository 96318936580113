export default function(context) {
	if($('body').data('page-type') == 'category' || $('body').data('page-type') == 'brand'){
		const urlParams = new URLSearchParams(window.location.search);
		const limit = urlParams.get('limit');

		if(limit === null){
			// do nothing
		} else {
			$('.product-count-form select').val(limit).trigger('change.select2');
		}

		function changeProductCount(event){
			let select = $(event.currentTarget);
			let value = select.val();

			var searchParams = new URLSearchParams(window.location.search);

			searchParams.set("limit", value);
		    window.location.search = searchParams.toString();
		}

		$('.product-count-form select').on('change', function(e){
			e.preventDefault();
			changeProductCount(e);
		})
	}
}
