export default function () {
    $('.ob-heroHeading__link').on('click', (e) => {
        e.preventDefault();
        const $description = document.querySelector('#category-description');

        if ($description) {
            $description.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    });
}
