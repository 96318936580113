function initCardGridCarousel(element) {
    const $context = $(element);
    const $carousel = $context.find('.ob-cardGrid__grid');

    $carousel.slick({
        slidesToShow: 2.3,
        slidesToScroll: 2,
        slide: '.ob-cardGrid__card',
        centerMode: false,
        infinite: false,
        dots: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 3.3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 801,
                settings: 'unslick',
            },
        ],
    });
}

export default function () {
    $('.ob-cardGrid').each((index, element) => initCardGridCarousel(element));
}
