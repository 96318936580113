/* eslint-disable function-paren-newline */
export default function ($scope) {
    const $carousel = $('.productView-images--mainCarousel-container:not(.slick-initialized)', $scope);
    const $carouselThumbs = $('.productView-images--navCarousel-container:not(.slick-initialized)', $scope);

    if ($carousel.length > 0) {
        $carousel.slick({
            slidesToShow: 1.1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            infinite: false,
            mobileFirst: true,
            swipeToSlide: true,
            nextArrow: `<button class="ob-btnIcon ob-btnIcon--chevron productView-images--mainCarousel-next">
            <svg class="ob-icon ob-icon--chevron">
                <use xlink:href="#icon-chevron-right"></use>
            </svg>
        </button>`,
            prevArrow: `<button class="ob-btnIcon ob-btnIcon--chevron productView-images--mainCarousel-prev">
            <svg class="ob-icon ob-icon--chevron">
                <use xlink:href="#icon-chevron-left"></use>
            </svg>
        </button>`,
            useTransform: false,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                        arrows: true,
                    },
                },
            ],

        });
    }

    if ($carouselThumbs.length > 0) {
        $carouselThumbs.slick({
            slidesToShow: 5,
            slidesToScroll: 5,
            dots: false,
            arrows: true,
            infinite: false,
            mobileFirst: true,
            swipeToSlide: true,
            vertical: true,
            nextArrow: `<button class="ob-btnIcon ob-btnIcon--chevron productView-images--navCarousel-next">
            <svg class="ob-icon ob-icon--chevron">
                <use xlink:href="#icon-chevron-right"></use>
            </svg>
        </button>`,
            prevArrow: `<button class="ob-btnIcon ob-btnIcon--chevron productView-images--navCarousel-prev">
            <svg class="ob-icon ob-icon--chevron">
                <use xlink:href="#icon-chevron-left"></use>
            </svg>
        </button>`,
            useTransform: false,
        });
    }

    const onMainCarouselChange = (e, slick, currentSlide) => {
        $carouselThumbs.find('.slick-slide').removeClass('mainCarousel--active');

        const $currentSlide = $carouselThumbs.find(`.slick-slide[data-slick-index="${currentSlide}"]`);
        $carouselThumbs.slick('slickGoTo', currentSlide);
        $currentSlide.addClass('mainCarousel--active');
    };

    const onCarouselThumbClick = (e) => {
        e.preventDefault();
        const $thumbnail = $(e.currentTarget);
        $carousel.slick('slickGoTo', $thumbnail.data('slick-index'));

        $carouselThumbs.find('.slick-slide').removeClass('mainCarousel--active');
        $thumbnail.addClass('mainCarousel--active');
    };

    $carouselThumbs.find('.slick-slide').on('click', onCarouselThumbClick);
    $carousel.on('afterChange', onMainCarouselChange);
}
