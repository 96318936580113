function initCarousel(element) {
    const $context = $(element);
    const $carousel = $context.find('.ob-brandBanner__carousel');

    $carousel.slick({
        slidesToShow: 2.5,
        slidesToScroll: 2,
        centerMode: false,
        infinite: false,
        dots: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 551,
                settings: {
                    slidesToShow: 4.5,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
        ],
    });
}

export default function () {
    $('.ob-brandBanner').each((index, element) => initCarousel(element));
}
