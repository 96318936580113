function bindToggle() {
    $('.mobileMenu-toggle').on('click', (e) => {
        e.preventDefault();

        const headerHeight = $('.bttn-header').height();

        $(e.currentTarget).toggleClass('is-open');

        $('.bttn-mobileMenu')
            .toggleClass('is-open')
            .css({ height: `calc(100vh - ${headerHeight - 3}px)` });

        // $('.bttn-mobileMenu')
        //     .toggleClass('is-open')
        //     .css({ height: 'vh' });
        
        $('body').toggleClass('ob-noScroll');

        const $bulkStatusBar = $('.ob-bulkStatusBar');

        if ($bulkStatusBar.length) {
            if ($bulkStatusBar.css('transform') === 'none') {
                $bulkStatusBar.css('transform', 'translateY(100%)');
            } else {
                $bulkStatusBar.css('transform', 'none');
            }
        }
    });
}

function bindCategoryNav() {
    // top level dropdown
    $('.bttn-mobileCategoryMenu__item.has-children .bttn-mobileCategoryMenu__itemContainer').on('click', (e) => {
        e.preventDefault();

        const $target = $(e.currentTarget);
        $target.parent().toggleClass('is-open');
        $target.parent().find('.bttn-mobileCategoryMenu__subNav').slideToggle();
    });

    // second level dropdown
    $('.bttn-mobileCategoryMenu__subNav-item.has-children .bttn-mobileCategoryMenu__subNav-link').on('click', (e) => {
        e.preventDefault();

        const $target = $(e.currentTarget);

        $target.parent().toggleClass('is-open');
        $target.parent().find('.bttn-mobileCategoryMenu__subSubNav').slideToggle();
    });
}

export default function () {
    bindToggle();
    bindCategoryNav();
}
